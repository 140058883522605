<template>
  <div class="ticker">
    <transition-group name="ticker" tag="div" class="ticker-container">
      <div v-if="currentNotice" v-show="isVisible" :key="currentNotice.notiId" class="ticker-item mt-0.5" @click="handleClick(currentNotice)">
        {{ currentNotice.tit }}
      </div>
    </transition-group>
  </div>
</template>

<script setup>
const api = useApi();
const { createPanel } = useWindowStore();
const noticeItems = ref([]);
const currentIndex = ref(0);
const tickerInterval = ref(null);
const isVisible = ref(true);
const count = 10;
const screenList = useScreenList();
const currentNotice = computed(() => {
  return noticeItems.value[currentIndex.value] || { notiId: '0', tit: '' };
});

onMounted(async () => {
  await getNoticeItems();
  startTicker();
});

onBeforeUnmount(() => {
  if (tickerInterval.value) {
    clearInterval(tickerInterval.value);
  }
});

const getNoticeItems = async () => {
  const { selectNoticeTicker } = await api(`/api/main/selectNoticeTicker.do`, {
    noticTickerVO: {
      count: count,
    },
  });
  noticeItems.value = selectNoticeTicker;
};

const startTicker = () => {
  tickerInterval.value = setInterval(() => {
    isVisible.value = false; // Hide current item
    setTimeout(() => {
      currentIndex.value = (currentIndex.value + 1) % noticeItems.value.length;
      isVisible.value = true; // Show next item
    }, 500); // Match this duration with the animation duration
  }, 5500);
};

const handleClick = async (notice) => {
  const noticeScreen = screenList.value.find((screen) => screen.id === 'U10103');

  if (noticeScreen) {
    await createPanel(noticeScreen, notice);
  }
};
</script>

<style scoped>
.ticker {
  background-color: #fff;
  color: #333;
  text-align: left;
  font-size: 1.2em;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 23px; /* Adjust height as needed */
}

.ticker-container {
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ticker-item {
  display: flex;
  align-items: center;
  height: 20px; /* Must match the container height */
  line-height: 20px; /* Center text vertically */
  white-space: nowrap;
  animation: ticker-item-fade 0.5s ease-in-out; /* Animation for fade out */
  cursor: pointer;
}

.ticker-enter-active {
  animation: ticker-fade-in 0.5s ease-in-out;
}

.ticker-leave-active {
  animation: ticker-fade-out 0.5s ease-in-out;
}

@keyframes ticker-fade-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ticker-fade-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
</style>
